import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LowCodeDevelopmentApply from "src/views/home/LowCodeDevelopment/LowCodeDevelopmentApply";

const HomeRoutes = {
  layout: HomeLayout,
  path: "*",
  routes: [
    {
      exact: true,
      path: "/contact",
      component: lazy(() => import("src/views/home/ContactView")),
    },
    {
      exact: true,
      path: "/",
      component: lazy(() => import("src/views/home/LandingPageView")),
    },
    {
      exact: true,
      path: "/career",
      component: lazy(() => import("src/views/home/CareerView")),
    },
    {
      exact: true,
      path: "/labelease",
      component: lazy(() => import("src/views/home/Labelease")),
    },
    {
      exact: true,
      path: "/Solace",
      component: lazy(() => import("src/views/home/Solace")),
    },
    {
      exact: true,
      path: "/Zebra",
      component: lazy(() => import("src/views/home/Zebra")),
    }, 
    {
      exact: true,
      path: "/Mendix",
      component: lazy(() => import("src/views/home/Mendix")),
    }, 
    {
      exact: true,
      path: "/LogisticsSupplyChain",
      component: lazy(() => import("src/views/home/LogisticsSupplyChain")),
    },
    {
      exact: true,
      path: "/Shipping",
      component: lazy(() => import("src/views/home/Shipping")),
    }, 
    {
      exact: true,
      path: "/Retail",
      component: lazy(() => import("src/views/home/Retail")),
    },
    {
      exact: true,
      path: "/Manufacturing",
      component: lazy(() => import("src/views/home/Manufacturing")),
    },
    {
      exact: true,
      path: "/Governments",
      component: lazy(() => import("src/views/home/Governments")),
    },
    {
      exact: true,
      path: "/PeopleIntegrator",
      component: lazy(() => import("src/views/home/PeopleIntegrator")),
    },
    {
      exact: true,
      path: "/LowCodeDevelopment",
      component: lazy(() => import("src/views/home/LowCodeDevelopment")),
    },
    {
      exact: true,
      path: "/MobileApplicationDevelopment",
      component: lazy(() => import("src/views/home/MobileApplicationDevelopment")),
    },
    {
      exact: true,
      path: "/BFSI",
      component: lazy(() => import("src/views/home/BFSI")),
    },
    {
      exact: true,
      path: "/DeliveryApproach",
      component: lazy(() => import("src/views/home/DeliveryApproach")),
    },
    {
      exact: true,
      path: "/robot",
      component: lazy(() => import("src/views/home/Robot")),
    },
    {
      exact: true,
      path: "/ApplicationModernization",
      component: lazy(() => import("src/views/home/ApplicationModernization")),
    },
    {
      exact: true,
      path: "/Assettracker",
      component: lazy(() => import("src/views/home/Assettracker")),
    },
    {
      exact: true,
      path: "/components",
      component: lazy(() => import("src/views/home/ComponentsView")),
    },
    {
      exact: true,
      path: "/sidebar",
      component: lazy(() => import("src/layouts/HomeLayout/Sidebar")),
    },
    {
      exact: true,
      path: "/mendix",
      component: lazy(() => import("src/views/home/MendixView")),
    },
    {
      exact: true,
      path: "/case-studies/:caseStudyId",
      component: lazy(() => import("src/views/home/Case-studies/Casestudies")),
    },
     {
      exact: true,
      path: "/case-studies/:caseStudyId",
      component: lazy(() => import("src/views/home/Case-studies/Casestudies")),
    },

    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default HomeRoutes;
